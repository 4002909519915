<template>
  <div class="wrapper-search-bottom">
    <el-container>
      <el-main class="pt0 pb0">
        <h2 class="section-subtitle">{{ $t(`${base}.title`) }}</h2>
      </el-main>
    </el-container>

    <LadContainerMediaText>
      <template slot="title"><span></span></template>

      <template slot="image">
        <div class="wrapper-video" @click="hadleOpen">
          <svg-icon :name="`local-flex/logo-white`" class="sprite-icons" />

          <!-- <div class="wrapper-play">
            <i class="play-video el-icon-video-play" />
            <p>{{ $t(`local_flex.watch_video`) }}</p>
          </div> -->
        </div>
      </template>

      <ul slot="description" class="list-unstyled">
        <li v-for="(section, i) in sections" :key="i">
          <svg-icon
            :name="`local-flex/${section}-white`"
            class="sprite-icons"
          />

          <div>
            <h3>{{ $t(`${base}.${section}.title`) }}</h3>
            <p>{{ $t(`${base}.${section}.description`) }}</p>
          </div>
        </li>
      </ul>
    </LadContainerMediaText>

    <LazyHydrate never :trigger-hydration="dialog.show">
      <LadVideoDialog
        v-if="dialog.show"
        :open="dialog.show"
        :src="video"
        @close="handleClose"
      />
    </LazyHydrate>

    <el-container>
      <el-main class="pt0">
        <p class="terms-and-conditions">
          <nuxt-link to="/localflex">
            {{ $t(`${base}.know_more`) }}
            <i class="el-icon-d-arrow-right el-icon--right" />
          </nuxt-link>
        </p>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { LadVideoDialog } from '@/components/pages/landing/video'

export default {
  name: 'LadLocalFlexSummary',
  components: {
    LadContainerMediaText: () =>
      import(
        /* webpackChunkName: "lad-container-media-text" */ '@/components/container-media-text/index'
      ),
    LadVideoDialog,
  },
  data() {
    return {
      base: 'local_flex.summary',
      sections: ['booking', 'financing', 'warranty'],
      dialog: {
        show: false,
      },
    }
  },
  computed: {
    video() {
      const common = `https://cdn.localadventures.io/web-client/videos/localflex-{domain}.{type}`
      const domain = process.env.APP_URL.endsWith('.travel') ? 'travel' : 'mx'

      return {
        mp4: common.replace('{domain}', domain).replace('{type}', 'mp4'),
        webm: common.replace('{domain}', domain).replace('{type}', 'webm'),
        ogg: common.replace('{domain}', domain).replace('{type}', 'ogg'),
      }
    },
  },
  mounted() {
    if (this.$route.query.video === 'localflex') {
      this.LadNextTick(() => (this.dialog.show = true), 'LadLocalFlexSummary')
    }
  },
  methods: {
    hadleOpen() {
      // this.dialog.show = true
    },
    handleClose() {
      // this.dialog.show = false
    },
  },
}
</script>

<style lang="scss">
.wrapper-search-bottom {
  /** IMPORTANT: esta property hay que ponerla tambien en el componente superior a este */
  flex-direction: column;

  .section-subtitle {
    text-align: center;
    margin: 0;
    color: white;
    text-transform: uppercase;
    font-size: 2em;

    @media screen and (max-width: 700px) {
      margin-top: 20px;
      padding: 0 20px;
      line-height: 1.3;
      font-size: 1.5em;
    }
  }

  .lad-container-media-text {
    padding: 0;

    &--wrapper {
      overflow: hidden;
    }

    &--right {
      order: 1;
      flex-basis: 60%;
    }

    &--left {
      order: 2;
      flex-basis: 40%;
    }

    h3 {
      margin: 0;
      color: white;
      text-transform: uppercase;
      font-size: 1.3em;

      & + p {
        margin: 0;
        color: white;
      }
    }

    .el-main {
      padding-bottom: 0;
    }
  }

  .list-unstyled {
    display: flex;
    flex-direction: column;

    li {
      width: 100%;
      padding: 0 0 0 100px;
      min-height: 95px;
    }

    .sprite-icons {
      position: absolute;
      width: 90px;
      height: 90px;
      left: 0;
    }
  }

  .wrapper-video {
    position: relative;
    max-width: 500px;
    margin: 0 auto;
    height: 300px;
    // cursor: pointer;
    // background-color: rgba(245, 245, 245, 0.15);

    img {
      width: auto;
      height: 100%;
      display: block;
      margin: 0 auto;
    }

    .sprite-icons {
      width: 300px;
      height: 300px;
    }

    .wrapper-play {
      position: absolute;
      right: 10px;
      bottom: 5px;
      z-index: 10;
      color: white;

      i {
        font-size: 4em;
      }

      p {
        margin: 0;
        color: white;
        text-transform: uppercase;
        text-shadow: 2px 2px 15px #464646;
      }
    }
  }

  .pt0 {
    padding-top: 0;
  }
  .pb0 {
    padding-bottom: 0;
  }

  .terms-and-conditions {
    text-align: right;
    color: white;
    margin: 0;
    font-style: italic;

    span {
      font-weight: 600;
    }

    @media screen and (max-width: 700px) {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 500px) {
    .lad-container-media-text {
      &--left {
        text-align: left;
        width: 100%;

        .list-unstyled {
          margin-bottom: 0;
        }
      }

      .el-main {
        padding-bottom: 20px;
      }
    }
  }
}
</style>
