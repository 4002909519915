<template>
  <el-dialog
    :visible.sync="open"
    :before-close="handleClose"
    :destroy-on-close="true"
    class="lad-landing-video-dialog"
    append-to-body
    width="60%"
  >
    <video v-if="open" controls width="100%" :autoplay="true">
      <source v-if="hasWebm" :src="video.webm" type="video/webm" />
      <source v-if="hasMp4 || hasExVideo" :src="video.mp4" type="video/mp4" />
      <source v-if="hasOgg" :src="video.ogg" type="video/ogg" />
    </video>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LadVideoDialog',
  props: {
    open: { type: Boolean, required: true },
    src: {
      type: Object,
      required: false,
      default: () => ({ mp4: '', webm: '', ogg: '' }),
    },
  },
  computed: {
    ...mapState('experience', {
      exVideo: (state) => state.experience.videos.website,
    }),
    video() {
      return {
        mp4: this.hasMp4 ? this.src.mp4 : this.exVideo,
        webm: this.hasWebm ? this.src.webm : '',
        ogg: this.hasOgg ? this.src.ogg : '',
      }
    },
    hasExVideo() {
      return !!this.exVideo
    },
    hasMp4() {
      return !!this.src.mp4
    },
    hasWebm() {
      return !!this.src.webm
    },
    hasOgg() {
      return !!this.src.ogg
    },
  },
  methods: {
    handleClose() {
      this.$emit('close', true)
    },
  },
}
</script>

<style lang="scss">
.lad-landing-video-dialog {
  .el-dialog {
    background: transparent;
    margin-top: 5vh !important;
    margin-bottom: 5vh !important;

    &__header {
      position: relative;
      z-index: 5;
    }

    &__body {
      display: flex;
      align-items: center;
      max-height: 90vh;

      video {
        max-height: 90vh;
      }
    }

    &__headerbtn {
      border: 2px solid white;
      border-radius: 50%;
      padding: 9px 10px;
    }

    &__close {
      color: white;
      font-weight: 700;
    }

    @media screen and (max-width: 450px) {
      margin-top: 1vh !important;
      margin-bottom: 1vh !important;

      &__body {
        max-height: 98vh !important;
      }
    }
  }
}
</style>
