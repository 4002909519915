<template>
  <div class="lad-video-section">
    <el-container>
      <el-aside class="lad-video-section--aside">
        <div class="lad-video-section--aside-wrapper">
          <h2>{{ $t(`${base}.title`) }}</h2>
          <p>{{ $t(`${base}.description`) }}</p>
        </div>
      </el-aside>

      <el-main class="lad-video-section--wrapper">
        <video controls width="100%" :autoplay="autoplay" :muted="autoplay">
          <source :src="video" type="video/mp4" />
        </video>
      </el-main>
    </el-container>

    <div class="lad-video-section--bg"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LadVideoSection',
  data() {
    return {
      base: 'landing.video',
      autoplay: false,
    }
  },
  computed: {
    ...mapState('experience', {
      video: (state) => state.experience.videos.website,
    }),
  },
  created() {
    this.setAutoplay()
  },
  methods: {
    setAutoplay() {
      const connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection

      if (connection) {
        this.autoplay = connection.effectiveType === '4g'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.lad-video-section {
  background-color: #fff;
  position: relative;
  padding: 30px;

  .lad-video-section--aside {
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    h2 {
      font-size: 30px;
    }

    p {
      margin-bottom: 30px;
      font-weight: bold;
      font-size: 1.1em;
      color: #777;
    }
  }

  &--wrapper {
    position: inherit;
    padding: 20px;
    z-index: 1;

    video {
      display: block;
    }
  }

  &--bg {
    background: rgb(48, 134, 66);
    background: -moz-linear-gradient(
      0deg,
      rgba(48, 134, 66, 1) 0%,
      rgba(37, 160, 72, 1) 65%,
      rgba(67, 190, 93, 1) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(48, 134, 66, 1) 0%,
      rgba(37, 160, 72, 1) 65%,
      rgba(67, 190, 93, 1) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(48, 134, 66, 1) 0%,
      rgba(37, 160, 72, 1) 65%,
      rgba(67, 190, 93, 1) 100%
    );
    filter: progid:dximagetransform.microsoft.gradient(startColorstr="#308642",endColorstr="#43be5d",GradientType=1);
    clip-path: polygon(50% 0, 100% 100%, 100% 0);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  @media screen and (max-width: 768px) {
    background: rgb(48, 134, 66);
    background: -moz-linear-gradient(
      0deg,
      rgba(48, 134, 66, 1) 0%,
      rgba(37, 160, 72, 1) 65%,
      rgba(67, 190, 93, 1) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(48, 134, 66, 1) 0%,
      rgba(37, 160, 72, 1) 65%,
      rgba(67, 190, 93, 1) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(48, 134, 66, 1) 0%,
      rgba(37, 160, 72, 1) 65%,
      rgba(67, 190, 93, 1) 100%
    );
    filter: progid:dximagetransform.microsoft.gradient(startColorstr="#308642",endColorstr="#43be5d",GradientType=1);
    padding: 0;

    .lad-video-section--aside {
      text-align: center;
      padding: 30px;

      h2,
      p {
        color: #fff;
      }
    }

    &--wrapper {
      padding: 0;
    }

    &--bg {
      display: none;
    }
  }
}
</style>
